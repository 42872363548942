<template>
  <div class="home">
    <div class="banner"><img src="../assets/images/banner1.jpg" alt="" /></div>
    <div class="current-position">
      <el-breadcrumb>
        <el-breadcrumb-item :to="{ path: '/' }"
          ><span class="icon-home"></span>首页</el-breadcrumb-item
        >
        <el-breadcrumb-item>行业资讯</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="news">
      <el-timeline
        v-infinite-scroll="load"
        infinite-scroll-disabled="disabled"
        infinite-scroll-distance="3000"
      >
        <el-timeline-item
          v-for="item in newsList.data"
          :timestamp="item.create_time"
          :key="item.content_id"
          placement="top"
        >
          <el-card>
            <h4>
              <a @click="zxPay(item.content_id)">
                {{ item.title }}
              </a>
            </h4>
            <p>{{ item.summary }}</p>
          </el-card>
        </el-timeline-item>
        <el-divider v-if="loading">加载中...</el-divider>
        <el-divider v-if="nodata">暂无数据</el-divider>
        <el-divider v-if="noMore">没有更多了</el-divider>
      </el-timeline>
    </div>
    <el-dialog
      title=""
      :visible.sync="paydialog"
      width="20%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <span style="color:red;font-size:12px:margin:20px;height:30px;width:100%">
        请支付一元后即可查看
      </span>
      <div style="text-align: center;margin-top:8px">
        <img
          src="../assets/images/fukuan.jpg"
          alt=""
          width="65%"
          height="65%"
        />
      </div>
      <!-- <div class="wechat-pay">
        <el-form
          :model="ruleForm"
          status-icon
          ref="ruleForm"
          class="demo-loginForm"
          label-width="55px"
        >
          <el-form-item prop="idcard" label="账号：">
            <el-input
              v-model="ruleForm.idcard"
              prefix-icon="el-icon-postcard"
              placeholder="账号/有效身份证件号"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password" label="密码：">
            <el-input
              show-password
              v-model="ruleForm.password"
              prefix-icon="icon-locks"
              placeholder="默认密码123456"
            ></el-input>
          </el-form-item>
          <el-button
            type="primary"
            style="width:20%"
            @click="submitForm('ruleForm')"
            >登录
          </el-button>
        </el-form>
      </div> -->
    </el-dialog>
  </div>
</template>

<script>
import { articleList } from '../api/article'
import { getResult } from '../utils/auth'
export default {
  name: 'Home',
  data() {
    return {
      loading: false,
      loadingData: [],
      newsList: {
        total: 2,
        data: []
      },
      category_id: 25,
      paydialog: false,
      ruleForm: {
        idcard: '',
        password: ''
      }
    }
  },
  computed: {
    noMore() {
      return this.newsList.data.length > this.newsList.total
    },
    nodata() {
      return this.newsList.data.length < this.newsList.total
    },
    disabled() {
      return this.loading || this.noMore || this.nodata
    }
  },
  created() {
    this.getCarData()
  },
  methods: {
    zxPay(content_id) {
      if (getResult() === undefined) {
        this.paydialog = true
      } else {
        this.$router.push({
          path: '/news/detail?id=' + content_id + '&navbar_title=行业资讯'
        })
      }
    },
    submitForm() {
      this.paydialog = false
    },
    handleClose() {
      this.paydialog = false
    },
    //获取汽车空间数据
    getCarData() {
      articleList({ category_id: this.category_id }).then(res => {
        if (res.code === 0) {
          this.newsList.data = res.result.slice(0, 2)
          this.loadingData = res.result.slice(2)
        }
      })
    },
    getData() {
      return this.loadingData
    },
    load() {
      this.loading = true
      setTimeout(() => {
        let data = this.getData()
        data.forEach(item => {
          this.newsList.data.push(item)
        })
        this.loading = false
      }, 2000)
    }
  },
  components: {}
}
</script>
